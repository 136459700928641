<template lang="pug">
div
  .wuwow-card
    .wuwow-card-head
      h4.h4-notification-title {{ $t('layout.editParentProfile') }}
    .wuwow-card-body
      .parent-profile-box
        .parent-info-img-box.mb-5
          b-row
            b-col
              .avatar-img
                b-img#image.float-right(v-if="parentInfo.avatar && parentInfo.avatar !== ''" v-bind="pictureProps" rounded="circle" :src="parentInfo.avatar" alt='photo')
                b-img.float-right(v-else src="https://cdn2.wuwow.tw/images/parentspic-circle.png" v-bind="pictureProps" rounded="circle" alt='photo')
            b-col
              div.mt-4.float-left
                span
                  | {{ parentInfo.chinese_name }}
                  br
                  | {{ parentInfo.english_name }}
          br
          .are-profile-fileupload
            button.btn.btn-outline-primary.btn-file-photo
              | {{ $t('upload') }}{{ $t('photo') }}
            input#image_file_upload_input.ipt-file-photo(type='file' name='file' accept='image/jpeg,image/jpg,image/png' @change="getImage()")
        .parent-info-list
          span.title 信箱
          span.content {{ parentInfo.email }}
        hr
        .parent-info-list
          span.title 手機
          span.content {{ parentInfo.phone }}
        hr
        .parent-info-list
          span.title 性別
          span.content {{ parentInfo.gender }}
        hr
        .parent-info-list
          span.title 密碼
          span.content
            a(:href="changePasswordUrl" target="_blank") 變更密碼
        div.text-center.mt-4(v-if="showConfirmButton")
          b-button(variant="outline-primary" @click="editParentInfo") 確定
</template>

<script>
import lioshutanApi from '@/api';
// import storage from '@/utils/localStorage';
import { alertSuccessMessage, alertFailedMessage } from '@/utils/sweetAlert.js';
import httpCodeConstants from '@/constants/httpCode';

export default {
  name: 'ParentsEdit',

  data() {
    return {
      parentInfo: {},
      changePasswordToken: '',
      pictureProps: {
        width: 100,
        height: 100,
        class: 'parent-picture',
      },
      showConfirmButton: false,
      // 從 API 取得的更改密碼用的token 來作為變更密碼頁面網址的 query string
      // changePasswordUrl: `/change-password?token=${storage.token}`,

      // 2023/06/13 權限模組登入中的家長所使用的重設密碼 API，不須攜帶 query string
      changePasswordUrl: '/change-password',
    };
  },

  created() {
    this.getParentInfo();
    // 2023/06/13 權限模組登入中的家長所使用的重設密碼 API，不須攜帶 query string
    // this.getChangePasswordUrl();
  },

  methods: {
    async getParentInfo() {
      const response = await lioshutanApi.parent.getParentInfo();
      const baseProfile = response.data.data.parent;
      this.parentInfo = baseProfile;
    },

    async getChangePasswordUrl() {
      const response = await lioshutanApi.parent.getChangePasswordToken();
      const token = response.data.data.token;
      this.changePasswordUrl = `/change-password?token=${token}`;
    },

    async editParentInfo() {
      try {
        const params = this.parentInfo;
        const formData = this.getFormData(params);
        const response = await lioshutanApi.parent.editParentInfo(formData);
        if (response.status === httpCodeConstants.SUCCESS) {
          alertSuccessMessage('上傳成功');
        } else {
          alertFailedMessage('上傳失敗');
        }
      } catch (error) {
        alertFailedMessage('資料處理錯誤');
      }
    },

    getFormData(updateParams) {
      const photoKey = 'avatar';
      const formData = new FormData();
      // eslint-disable-next-line
      for (let key in updateParams) {
        if (key === photoKey) {
          if (updateParams[key].size) {
            formData.append(key, updateParams[key]);
          }
        } else if (updateParams[key] !== '' && updateParams[key] !== null) {
          formData.append(key, updateParams[key]);
        }
      }
      return formData;
    },

    getImage() {
      const input = document.getElementById('image_file_upload_input');
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        reader.onload = (element) => {
          document.getElementById('image').src = element.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.parentInfo.avatar = input.files[0];
        this.showConfirmButton = true; // 更改圖片可上傳
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.parent-profile-box {
  width: 400px;
  margin: 0 auto;

  .parent-picture {
    margin-right: -20px;
  }

  .parent-info-img-box {
    text-align: center;

    .avatar-img {
      width: 200px;
      height: auto;

      img {
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .parent-info-list {
    margin-left: 80px;

    .title {
      color: var(--text-light-color);
    }

    .content {
      margin-left: 50px;
    }
  }

  .are-profile-fileupload {
    height: 35px;
    margin: 1rem 0;
    position: relative;

    .btn-file-photo,
    .ipt-file-photo {
      height: 35px;
      left: 50%;
      margin-left: -82.5px;
      position: absolute;
      top: 0;
      width: 135px;
      cursor: pointer;
    }

    .ipt-file-photo {
      opacity: 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .parent-profile-box {
    width: 100%;

    .parent-info-list {
      margin-left: 20px;

      .content {
        margin-left: 50px;
      }
    }
  }
}</style>
